var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ACard",
    {
      staticClass: "page-moved-to-finance",
      class: {
        "page-moved-to-finance--is-tab-view": _vm.isTabView
      },
      attrs: { title: _vm.title }
    },
    [
      _c(
        "div",
        { staticClass: "page-moved-to-finance__container" },
        [
          _c(
            "div",
            { staticClass: "page-moved-to-finance__description" },
            [
              _vm.isDescriptionIconAvailable
                ? _c(
                    "v-icon",
                    { staticClass: "page-moved-to-finance__description-icon" },
                    [_vm._v(" " + _vm._s(_vm.descriptionIcon) + " ")]
                  )
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.description) + " ")
            ],
            1
          ),
          _vm.isNavigateToNewPlatformButtonAvailable
            ? _c(
                "AButton",
                {
                  staticClass:
                    "page-moved-to-finance__navigate-to-new-platform-button",
                  attrs: {
                    href: _vm.navigateToNewPlatformButtonTo,
                    "prepend-inner-icon": "open_in_new",
                    target: "_blank"
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.navigateToNewPlatformButtonText) + " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }