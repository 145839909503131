import { Application } from '@/support/Application';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';

@Component<PageMovedToFinance>({})
export default class PageMovedToFinance extends Vue {
  @Prop()
  protected report!: any;

  // #region @PAUL: TOGGLE THIS

  protected isMoving = false;

  // #endregion

  // #region @PAUL: CHANGE THIS

  protected get description(): string {
    return this.isMoving
      ? 'De urentool wordt op dit moment gemigreerd naar de nieuwe omgeving. Probeer het morgen opnieuw!'
      : 'De urentool id definitief verplaatst naar de nieuwe omgeving. Klik op onderstaande knop om naar de omgeving te gaan:';
  }

  protected get descriptionIcon(): string {
    return 'engineering';
  }

  protected get isDescriptionIconAvailable(): boolean {
    return this.isMoving;
  }

  protected get isNavigateToNewPlatformButtonAvailable(): boolean {
    return ! this.isMoving;
  }

  protected get isTabView(): boolean {
    return !! this.report;
  }

  protected get navigateToNewPlatformButtonText(): string {
    return 'Open het nieuwe financiën platform';
  }

  protected get navigateToNewPlatformButtonTo(): RawLocation {
    switch ((Application.config('APP_APPLICATION_MODE') as string || '')) {
      case '10be':
        return 'https://10be.finance.atabase.nl';
      case 'ced':
        return 'https://ced.finance.atabase.nl';
      case 'dog':
        return 'https://dog.finance.atabase.nl';
      case 'tcmg':
        return 'https://img.finance.atabase.nl';
      case 'nivre':
        return 'https://nivre.finance.atabase.nl';
      default:
        return 'https://img.finance.atabase.nl';
    }
  }

  protected get title(): string {
    return this.isMoving
      ? 'Pagina wordt momenteel verplaatst'
      : 'Urentool is verplaatst';
  }

  // #endregion
}
